import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "base",
    redirect: "/resume",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // demo leaflet
      {
        path: "/leaflet",
        name: "leaflet",
        component: () => import("@/views/pks/leaflet/test.vue"),
      },
      // end demo
      {
        path: "/resume",
        name: "resume",
        component: () => import("@/views/pks/Resume/index.vue"),
      },
      {
        path: "/heatmaps",
        name: "heat-maps",
        component: () => import("@/views/pks/Heatmaps/index.vue"),
      },
      {
        path: "/heatmaps/chart/:id",
        name: "heat-maps.chart",
        component: () => import("@/views/pks/Heatmaps/chart.vue"),
        props: true,
      },
      {
        path: "/heatmaps/chart/detail/:id/:wilayah",
        name: "heat-maps.chart.detail",
        component: () => import("@/views/pks/Heatmaps/chart-detail.vue"),
        props: true,
      },
      {
        path: "/zone-map",
        name: "zone-maps",
        component: () => import("@/views/pks/ZoneMap/index.vue"),
      },
      {
        path: "/kader-map",
        name: "kader-maps",
        component: () => import("@/views/pks/KaderMap/index.vue"),
      },
      {
        path: "/map-comparison",
        name: "comparison.map",
        component: () => import("@/views/pks/ComparisonMap/index.vue"),
      },
      {
        path: "/comparison-map/detail/:id/:year",
        name: "comparison.map.detail",
        component: () => import("@/views/pks/ComparisonMap/detail.vue"),
        props: true,
        children: [
          {
            path: "/comparison-map/detail/pileg/:id/:year",
            name: "comparison.map.detail.pileg",
            component: () => import("@/views/pks/ComparisonMap/pileg.vue"),
            props: true,
          },
          {
            path: "/comparison-map/detail/pilkada/:id/:year",
            name: "comparison.map.detail.pilkada",
            component: () => import("@/views/pks/ComparisonMap/pilkada.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/ktanisasi",
        name: "ktanisasi",
        component: () => import("@/views/pks/Ktanisasi/index.vue"),
        children: [
          {
            path: "/ktanisasi/caleg",
            name: "ktanisasi.caleg",
            component: () => import("@/views/pks/Ktanisasi/caleg.vue"),
          },
          {
            path: "/ktanisasi/dpc",
            name: "ktanisasi.dpc",
            component: () => import("@/views/pks/Ktanisasi/dpc.vue"),
          },
        ],
      },
      {
        path: "/ktanisasi/detail-dpc/:id",
        name: "ktanisasi.detail.dpc",
        component: () => import("@/views/pks/Ktanisasi/detail-dpc.vue"),
        props: true,
      },
      {
        path: "/ktanisasi/detail-caleg/:id",
        name: "ktanisasi.detail.caleg",
        component: () => import("@/views/pks/Ktanisasi/detail-caleg.vue"),
        props: true,
      },
      {
        path: "/person-map/cluster",
        name: "person-map-cluster",
        component: () => import("@/views/pks/PersonMap/pro-cluster.vue"),
      },

      {
        path: "/person-map/pro",
        name: "person-map-pro",
        component: () => import("@/views/pks/PersonMap/pro.vue"),
      },
      {
        path: "/person-map",
        name: "person-map",
        component: () => import("@/views/pks/PersonMap/index.vue"),
        children: [
          // {
          //   path: "/person-map/pro",
          //   name: "person-map-pro",
          //   component: () => import("@/views/pks/PersonMap/pro.vue"),
          // },
          {
            path: "/person-map/kontra",
            name: "person-map-kontra",
            component: () => import("@/views/pks/PersonMap/kontra.vue"),
          },
          {
            path: "/person-map/netral",
            name: "person-map-netral",
            component: () => import("@/views/pks/PersonMap/netral.vue"),
          },
          {
            path: "/person-map/detail/:id",
            name: "person-map-pro.detail",
            component: () => import("@/views/pks/PersonMap/detail.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/movement-map",
        name: "movement-map",
        component: () => import("@/views/pks/MovementMap/index.vue"),
        children: [
          {
            path: "/movement-map/map",
            name: "movement-map.map",
            component: () => import("@/views/pks/MovementMap/map.vue"),
          },
          {
            path: "/movement-map/chart",
            name: "movement-map.chart",
            component: () => import("@/views/pks/MovementMap/chart.vue"),
          },
        ],
      },
      {
        path: "/movement-map/detail/:id",
        name: "movement-map.detail",
        component: () => import("@/views/pks/MovementMap/detail.vue"),
        props: true,
      },
      {
        path: "/violation-map",
        name: "violation-map",
        component: () => import("@/views/pks/ViolationMap/index.vue"),
      },
      {
        path: "/issue-map",
        name: "issue-map",
        component: () => import("@/views/pks/IssueMap/index.vue"),
      },
      {
        path: "/advokasi-map",
        name: "advokasi-map",
        component: () => import("@/views/pks/AdvokasiMap/index.vue"),
      },
      {
        path: "/attribute-map/cluster",
        name: "attribute-map-cluster",
        component: () => import("@/views/pks/AttributeMap/index-cluster.vue"),
      },
      {
        path: "/attribute-map",
        name: "attribute-map",
        component: () => import("@/views/pks/AttributeMap/index.vue"),
      },
      {
        path: "/attribute-map/detail/:id",
        name: "attribute-map-detail",
        component: () => import("@/views/pks/AttributeMap/detail.vue"),
        props: true,
      },
      {
        path: "/tps-map",
        name: "tps-map",
        component: () => import("@/views/pks/TpsMap/index.vue"),
      },
      {
        path: "/tps-map/table",
        name: "tps-map.table",
        component: () => import("@/views/pks/TpsMap/table.vue"),
      },
      {
        path: "/result",
        name: "result",
        component: () => import("@/views/pks/Result/index.vue"),
        children: [
          {
            path: "/result/pks",
            name: "result-pks",
            component: () => import("@/views/pks/Result/pks.vue"),
          },
          {
            path: "/result/summary",
            name: "result-summary",
            component: () => import("@/views/pks/Result/summary.vue"),
          },
        ],
      },
      {
        path: "/issue",
        name: "issue",
        component: () => import("@/views/pks/Issue/index.vue"),
      },
      {
        path: "/jaringtokoh",
        name: "jaringtokoh",
        component: () => import("@/views/pks/Jaringtokoh/index.vue"),
      },
      {
        path: "/jaringtokoh/detail/:id",
        name: "jaringtokoh.detail",
        component: () => import("@/views/pks/Jaringtokoh/detail.vue"),
        props: true,
      },
      {
        path: "/sapatokoh",
        name: "sapatokoh",
        component: () => import("@/views/pks/Sapatokoh/index.vue"),
      },
      {
        path: "/sapatokoh/detail/:id",
        name: "sapatokoh.detail",
        component: () => import("@/views/pks/Sapatokoh/detail.vue"),
        props: true,
      },
      {
        path: "/kolektif",
        name: "kolektif",
        component: () => import("@/views/pks/Kolektif/index.vue"),
      },
      {
        path: "/kolektif/detail/:id",
        name: "kolektif.detail",
        component: () => import("@/views/pks/Kolektif/detail.vue"),
        props: true,
      },
      {
        path: "/tokoh",
        name: "tokoh",
        component: () => import("@/views/pks/Tokoh/index.vue"),
      },
      {
        path: "/tokoh/detail/:id",
        name: "tokoh.detail",
        component: () => import("@/views/pks/Tokoh/detail.vue"),
        props: true,
      },
    ],
  },
  // ADMIN
  {
    path: "/admin",
    name: "admin.panel",
    redirect: "/admin/dashboard",
    component: () => import("@/layout-admin/Layout.vue"),
    children: [
      {
        path: "/admin/dashboard",
        name: "admin.dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      // politic
      {
        path: "/admin/politic/parties",
        name: "admin.politic.parties",
        component: () => import("@/views/admin/politic/parties/index.vue"),
      },
      {
        path: "/admin/politic/kader/list",
        name: "admin.politic.kader/list",
        component: () => import("@/views/admin/politic/kader/list/index.vue"),
      },
      {
        path: "/admin/politic/kader/category",
        name: "admin.politic.kader.category",
        component: () =>
          import("@/views/admin/politic/kader/category/index.vue"),
      },
      // vote
      {
        path: "/admin/vote/zonings",
        name: "admin.vote.zonings",
        component: () => import("@/views/admin/vote/zonings/index.vue"),
      },
      {
        path: "/admin/vote/ktanisasi",
        name: "admin.vote.ktanisasi",
        component: () => import("@/views/admin/vote/ktanisasi/index.vue"),
      },
      {
        path: "/admin/vote/ktanisasi-sums",
        name: "admin.vote.ktanisasi-sums",
        component: () => import("@/views/admin/vote/ktanisasi/summary.vue"),
      },
      // master
      {
        path: "/admin/master/territory/levels",
        name: "admin.master.territory.levels",
        component: () => import("@/views/admin/master/territory/level.vue"),
      },
      {
        path: "/admin/master/attributions",
        name: "admin.master.attributions",
        component: () => import("@/views/admin/master/attributions/index.vue"),
      },
      {
        path: "/admin/master/position",
        name: "admin.master.position",
        component: () => import("@/views/admin/master/position/index.vue"),
      },
      // account
      {
        path: "/admin/account/confirm",
        name: "admin.account.confirm",
        component: () => import("@/views/admin/account/confirm/index.vue"),
      },
      {
        path: "/admin/account/users",
        name: "admin.account.users",
        component: () => import("@/views/admin/account/users/index.vue"),
      },
    ],
  },
  // Auth Page
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
    ],
  },
  // Others
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/tos",
    name: "tos",
    component: () => import("@/views/crafted/Tos.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH);
  store.dispatch("setMenu", "");

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
